export const step1 = require("./step1.png");
export const step2 = require("./step2.png");
export const step3 = require("./step3.png");
export const step4 = require("./step4.png");

export const join1 = require("./join1-2.png");
export const join2 = require("./join2-3.png");
export const join3 = require("./join3-4.png");


