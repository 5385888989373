export const discord = require("./discord.png");
export const linkedin = require("./linkedin.png");
export const reddit = require("./reddit.png");
export const snapchat = require("./snapchat.png");
export const telegram = require("./telegram.png");
export const tiktok = require("./tiktok.png");
export const wechat = require("./wechat.png");
export const whatsapp = require("./whatsapp.png");
export const google = require("./google.png");
export const youtube = require("./youtube.png");
export const twitter = require("./twitter.png");
export const instagram = require("./insta.png");
export const facebook = require("./facebook.png");
export const apple = require("./apple.png");
