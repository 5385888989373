import React from 'react';
import BusinessCalculator from '../components/Calculator/BusinessCalculator';

const Business = () => {
  return (
    <BusinessCalculator/>
  );
}

export default Business;
